<template>
  <section ref="pc_common" :class="`${classs} detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar title="movienightguide" :deviceType="deviceType" :isFullScreen="isFullScreen" />
    <slot />
    <FootBar v-show="!isFullScreen" :deviceType="deviceType" />
  </section>
</template>

<script>
import HeadBar from '@/pages/components/head.vue';
import FootBar from '@/pages/components/footer.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    HeadBar,
    FootBar
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    classs: {
      type: String,
      default: 'pc_common',
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    isFullScreen: Boolean, // 增加 isFullScreen 作为 props
  },
  computed: {
    ...mapGetters([
      'deviceType',
      'host',
    ])
  },

};
</script>